@use './variables.scss' as *;
@use './functions.scss' as *;

// Extra small devices (phones, 576px and down)
@mixin phones {
    @media (max-width: 576px) {
      @content;
    }
  }
  
  // Medium devices (tablets, 768px and up)
  @mixin tablets {
    @media (max-width: 768px) {
      @content;
    }
  }
  
  // Large devices (desktops, 992px and up)
  @mixin desktops {
    @media (max-width: 992px) {
      @content;
    }
  }
  
  // Extra large devices (large desktops, 1200px and up)
  @mixin large-desktops {
    @media (max-width: 1200px) {
      @content;
    }
  }
  
  // Extra extra large devices (larger desktops, 1400px and up)
  @mixin extra-large-desktops {
    @media (max-width: 1400px) {
      @content;
    }
  }
  
  // Custom breakpoint
  @mixin breakpoint($min-width) {
    @media (min-width: $min-width) {
      @content;
    }
  }
  
@mixin heading-text{
    font-family: $title-font;
    font-weight: normal;
    color: inherit;
    text-transform: uppercase;
    line-height: 1.1;
}
@mixin description{
  @include heading-text;
  line-height: 1.2;
  text-align: justify;
}

@mixin button{
  @include heading-text;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color;
  color: $white-color;
  border:none;
  border-radius:rem(72px);
  height: rem(72px);
  text-decoration: none;
  padding: 0 rem(8px) 0 rem(40px);
}