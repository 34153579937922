// variables.scss

// Цвета
$primary-color: #1935C4;

$white-color: #fff;
$black-color: #191919;

// Шрифты
$main-font: "Inter", sans-serif;
$title-font: "Anton", system-ui;

// Размеры
$base-font-size: 16px;
