@use '../../variables.scss' as *;
@use '../../functions.scss' as *;
@use '../../mixins.scss' as *;
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
}

.progressBar {
    width: 100%;
    height: rem(8px);
    background-color: #e0e0e0;
    overflow: hidden;
    margin-bottom: rem(24px);
    position: absolute;
    bottom: 0;
    left: 0;
}

.progress {
    height: 100%;
    background-color: $primary-color;
    transition: width 0.3s ease;
}

.progressText {
    position: absolute;
    bottom: rem(40px);
    right: rem(40px);
    font-family: $title-font;
    font-size: rem(160px);
    color: #191919;
}

@media screen and (max-width:568px) {
    .progressText {
        font-size: rem(88px);
    }
}