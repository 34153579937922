@use './functions.scss' as *;
@use './variables.scss' as *;
@use './mixins.scss' as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  overflow-x: hidden;
  font-family: $main-font;
}

h1,h2,h3,h4,h5,h6{
 @include heading-text
}

a{
  color:inherit;
  text-decoration: none;
}
.description {
  @include heading-text;
  text-align: justify;
  line-height: 1.5;
  &::before {
    content: '[';
    display: inline-block;
    margin-left: rem(24px);
    margin-right: rem(8px);
  } 
  &::after {
      content: ' ]';
      margin-left: rem(8px);
      display: inline-block;
  }
}

.content-wrapper{
  width: 100%;
  max-width: rem(1600px);
  margin: 0 auto;
  padding: 16px;
}
.title{
  @include heading-text;
  font-size: rem(88px);
  @include phones{
    font-size: rem(56px);
  }
}